const confetti = require('canvas-confetti');

const csrfToken = $('meta[name="csrf-token"]').attr('content');
const $continueToPayment = $('#continueToPayment');

const $form = $('#new_registration');
const $formInputs = $('[data-role="form-field"]');
const $membershipTierInput = $('select[data-role="membership-tier"]');

const $paymentContainer = $('div[data-role="payment-container"]');
const $paymentDisabledOverlay = $('div[data-role="payment-disabled-overlay"]');
const $paymentError = $('p[data-role="payment-error-message"]');
const $paymentTotalLabels = $('p[data-role="total-due"]')

const $flowContainer = $('div[data-role="flow-container"]');
const $confirmationModal = $('#confirmationModal');

const $alertContainer = $('div[data-role="alert-container"]');
const $alertMessage = $('p[data-role="alert-message"]');

const FORM_FLOW = 0;
const PAYMENT_FLOW = 1;

let registrationStep = FORM_FLOW;
let formData = null;

const cannon = confetti.create(null, {
    resize: true,
    useWorker: true,
});

const fire = () => {
    const _fire = (particleRatio, opts) => {
        cannon(Object.assign({ zIndex: 2000 }, { origin: { y: 0.7 } }, opts, {
            particleCount: Math.floor(200 * particleRatio)
        }));
    }

    _fire(0.25, { spread: 26, startVelocity: 55, });
    _fire(0.2, { spread: 60, });
    _fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
    _fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
    _fire(0.1, { spread: 120, startVelocity: 45, });
};

const isMobileLayout = () => {
    return $('div[data-role="mobile-check"]').is(':visible');
}

$(document).ready(() => {
    renderPayPal({});

    $membershipTierInput.change(handleMembershipTierChange);
});

function handleMembershipTierChange(event) {
    // Listen for changes to the membership tier input so we can update the total due
    const $tier = $(event.target).find('option:selected');
    const price = $tier.data('price');

    $paymentTotalLabels.html(price);
}

async function handleContinueToPayment(actions, event) {
    event.preventDefault();

    switch (registrationStep) {
        case FORM_FLOW:
            // Remove any existing validation errors
            $formInputs.removeClass('is-invalid');
            $alertContainer.slideUp();

            // Validate form
            const response = await validateForm();

            // Render errors if a bad response is returned
            if (!response.ok) {
                const { errors } = await response.json();

                renderValidationErrors(errors);
                return;
            }

            // Enable payment actions 
            actions.enable();

            $formInputs.prop('disabled', true);
            $paymentDisabledOverlay.fadeOut(250);
            $continueToPayment.fadeTo(250, 0, () => {
                $continueToPayment.addClass('edit-again');
                $continueToPayment.find('span').html('Edit Details Again');
                $continueToPayment.fadeIn(250);
                setTimeout(() => {
                    $continueToPayment.fadeTo(250, 1);
                }, 100)
            });

            if (isMobileLayout()) $paymentContainer.show();

            registrationStep = PAYMENT_FLOW;
            break;
        case PAYMENT_FLOW:
            actions.disable();

            $formInputs.prop('disabled', false);
            $paymentDisabledOverlay.fadeIn(250);
            $continueToPayment.fadeTo(250, 0, () => {
                $continueToPayment.removeClass('edit-again');
                $continueToPayment.find('span').html('Continue to Payment');
                setTimeout(() => {
                    $continueToPayment.fadeTo(250, 1);
                }, 100)
            });

            if (isMobileLayout()) $paymentContainer.slideUp(250);

            registrationStep = FORM_FLOW;
            break;
    }
};

async function validateForm() {
    $alertContainer.slideUp(500);

    formData = $form.serialize();

    const response = await fetch(
        '/validate',
        {
            method: 'POST',
            body: formData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
    );

    return response;
}

function renderValidationErrors(errors) {
    let $firstInvalidField = null;

    for (let field in errors) {
        field = field.replace('values.', '');

        const $field = $(`input[data-label="${field}"]`);

        if ($firstInvalidField == null ||
            $field.position().top < $firstInvalidField.position().top) {
            $firstInvalidField = $field;
        }

        $field.addClass('is-invalid');
    }

    $('html, body').animate({
        scrollTop: $firstInvalidField.offset().top - 50
    }, 250);

    addAlert('Please correct the highlighted fields, then try again');
}

async function renderPayPal() {
    if (typeof paypal === 'undefined') return;

    paypal.Buttons({
        onInit: (data, actions) => {
            actions.disable();

            $continueToPayment.click(handleContinueToPayment.bind(null, actions));
        },
        createOrder: async (data, actions) => {
            try {
                $paymentError.hide();

                const response = await fetch(
                    '/payments/orders',
                    {
                        method: 'POST',
                        headers: {
                            'X-CSRF-Token': csrfToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            order: {
                                membership_tier_id: $membershipTierInput.val(),
                            }
                        })
                    });

                if (!response?.ok) {
                    const errorBody = await response.json();

                    addCreatePaymentError(errorBody);

                    $paymentError.show();

                    return;
                }

                const { order } = await response.json();

                return order.id;
            } catch (e) {
                $paymentError.html(e.message);
                $paymentError.show();
            }
        },
        onApprove: async (data, actions) => {
            const { orderID: orderId } = data;
            const encodedConfirmationField = encodeURIComponent('registration[confirmation_id]');
            const encodedConfirmationValue = encodeURIComponent(orderId);

            const confirmationId = `${encodedConfirmationField}=${encodedConfirmationValue}`;
            const appendedFormData = `${formData}&${confirmationId}`

            const captureResponse = await fetch(`/payments/orders/${orderId}/capture`, {
                method: 'POST',
                headers: { 'X-CSRF-Token': csrfToken },
            });

            if (!captureResponse?.ok) {
                const errorBody = await captureResponse.json();

                addCapturePaymentError(errorBody);

                $paymentError.show();

                return;
            }

            const response = await fetch('/', {
                method: 'POST',
                body: appendedFormData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-CSRF-Token': csrfToken,
                },
            });

            await response.json();

            $flowContainer.hide();
            $confirmationModal.modal().show()
            setTimeout(() => { fire() }, 500);
        }
    }).render('#paypalButtons');
}

const addAlert = (text) => {
    $alertMessage.html(text);
    $alertContainer.slideDown({
        duration: 250,
        start: () => {
            $alertContainer.css({ display: "flex" })
        }
    });

    setTimeout(() => {
        $alertContainer.slideUp(250);
    }, 10000);
};

const addCreatePaymentError = (errorBody) => {
    if (errorBody?.details?.includes("PAYEE_ACCOUNT_INVALID")) {
        $paymentError.html('This organization\'s PayPal information is misconfigured. Please try again later');
    } else if (errorBody?.details?.includes("PAYEE_ACCOUNT_LOCKED_OR_CLOSED")) {
        $paymentError.html('This organization\'s PayPal account is locked and cannot receive payments at this time. Please try again later');
    } else if (errorBody?.details?.includes("PAYEE_ACCOUNT_RESTRICTED")) {
        $paymentError.html('This organization\'s PayPal account is restricted and cannot receive payments at this time. Please try again later');
    } else {
        $paymentError.html('We ran into a problem preparing your payment, you were not charged. Please try again, or if the issue persists come back later.');
    }

};

const addCapturePaymentError = (errorBody) => {
    if (errorBody?.details?.includes("INSTRUMENT_DECLINED")) {
        $paymentError.html('Your payment was declined. Please try again using a different payment method');
    } else if (errorBody?.details?.includes("CARD_EXPIRED") || errorBody?.details?.includes("CARD_CLOSED")) {
        $paymentError.html('Your selected card is expired or closed. Please try again using a different payment method');
    } else if (errorBody?.details?.includes("PAYER_ACCOUNT_LOCKED_OR_CLOSED") || errorBody?.details?.includes("PAYER_CANNOT_PAY")) {
        $paymentError.html('Your account is locked or frozen at this time. Please try again later.');
    } else if (errorBody?.details?.includes("MAX_NUMBER_OF_PAYMENT_ATTEMPTS_EXCEEDED")) {
        $paymentError.html('You have reached the maximum number of payment attempts. Please try again later');
    } else if (errorBody?.details?.includes("PAYEE_BLOCKED_TRANSACTION") || errorBody?.details?.includes("TRANSACTION_BLOCKED_BY_PAYEE")) {
        $paymentError.html('This organization\'s fraud settings flagged your transaction, contact the organization directly to learn more');
    } else if (errorBody?.details?.includes("PAYER_ACCOUNT_RESTRICTED") || errorBody?.details?.includes("PAYEE_NOT_ENABLED_FOR_CARD_PROCESSING")) {
        $paymentError.html('This organization\'s PayPal account is restricted and cannot receive payments at this time');
    } else if (errorBody?.details?.includes("NOT_ENABLED_FOR_CARD_PROCESSING")) {
        $paymentError.html('Your PayPal account is not configured for card payments. Please try again using a different payment method');
    } else {
        $paymentError.html('Uh oh, we ran into an issue processing your payment. Please try again later');
    }
};